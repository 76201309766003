import React from "react";
import { Component } from "react";
import { BrowserRouter } from "react-router-dom";

import "../../style.css";

import techno1 from "../../img/techno/techno1.png";
import techno2 from "../../img/techno/techno2.png";
import techno3 from "../../img/techno/techno3.png";
import techno4 from "../../img/techno/techno4.png";
import techno5 from "../../img/techno/techno5.png";
import techno6 from "../../img/techno/techno6.png";
import techno7 from "../../img/techno/techno7.png";
import techno8 from "../../img/techno/techno8.png";
import techno9 from "../../img/techno/techno9.png";
import techno10 from "../../img/techno/techno10.png";
import techno11 from "../../img/techno/techno11.png";
import techno12 from "../../img/techno/techno12.png";

import lefttechno from "../../img/left-techno.png";
import righttechno from "../../img/right-techno.png";

const Techno = () => (
	<div id="techno">
		<img className="techno-left no-mobile" src={lefttechno} />
		<h2 className="">Technologies we use</h2> {/*wow fadeInLeft*/}
		<div className="techno-nav">
			{" "}
			{/*wow fadeInRight*/}
			<ul>
				<li>All</li>
				<li>Languages</li>
				<li>Tools</li>
				<li>Databases</li>
				<li>Platforms</li>
			</ul>
		</div>
		<div className="techno-inner container">
			<div className="techno-item">
				<img src={techno5} />
			</div>

			<div className="techno-item">
				<img src={techno7} />
			</div>
			<div className="techno-item">
				<img src={techno8} />
			</div>
			<div className="techno-item">
				<img src={techno9} />
			</div>
			<div className="techno-item">
				<img src={techno10} />
			</div>
			<div className="techno-item">
				<img src={techno11} />
			</div>
			<div className="techno-item">
				<img src={techno12} />
			</div>
		</div>
		<img className="techno-right  no-mobile" src={righttechno} />
	</div>
);

export default Techno;
