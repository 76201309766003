import React from "react";
import { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import logo from "../logo.svg";
import "./App.css";
import "../style.css";
import { Switch, Route } from "react-router-dom";
import { Link } from "react-router-dom";
import toplogo from "../img/logo.png";
import imgslider from "../img/image.png";
import offermobile from "../img/mobile.png";
import icon1 from "../img/icon1.png";
import icon2 from "../img/icon2.png";
import icon3 from "../img/icon3.png";
import side from "../img/side.png";
import leftcontact from "../img/left-contact.png";
import rightcontact from "../img/right-contact.png";

import clutch from "../img/clutch.png";
import instagram from "../img/instagram.png";
import facebook from "../img/facebook.png";
import linkedin from "../img/linkedin.png";
import contactBackground from "../img/contact-background.png";

import amazon from "../img/amazon.png";
import img2 from "../img/img2.png";
import rect from "../img/rect.png";
import rect2 from "../img/rect2.png";

import Techno from "./Techno/Techno.js";
import Portfolio from "./Portfolio/Portfolio.js";
import Admin from "./Admin";

import { AnimationOnScroll } from "react-animation-on-scroll";

import "./animate.min.css";

const Main = () => (
  <main>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/services" component={Services} />

      <Route path="/webdev" component={Webdev} />
      <Route path="/ecommerce" component={Ecommerce} />
      <Route path="/ecommerce-support" component={EcommerceSupport} />
      <Route path="/uiux" component={Uiux} />
      <Route path="/mobdev" component={Mobdev} />

      <Route path="/projects" component={Projects} />
      <Route path="/company" component={Company} />
      <Route path="/contact" component={Contact} />
    </Switch>
  </main>
);

class App extends Component {
  componentDidMount() {
    const script1 = document.createElement("script");
    script1.src = "https://widget.clutch.co/static/js/widget.js";
    script1.async = true;
    document.body.appendChild(script1);
  }

  render() {
    return (
      <div className="App">
        <Header />
        <Main />
        <Footer />
      </div>
    );
  }
}

function Header() {
  return (
    <header className="header">
      <Logo />
      <Navigation />
    </header>
  );
}

function Logo() {
  return (
    <div className="site-logo">
      <Link to="/">
        <img src={toplogo} className="logo" alt="logo" />
      </Link>
    </div>
  );
}

class Navigation extends Component {
  render() {
    return (
      <div className="nav">
        <ul className="mainmenu">
          <li>
            <Link to="/">Home</Link>
          </li>

          <li>
            <Link to="/services">Services</Link>

            <ul className="submenu">
              <li>
                <Link to="/webdev">Web development</Link>
              </li>
              <li>
                <Link to="/mobdev">Mobile development</Link>
              </li>
              <li>
                <Link to="/uiux">UI \ UX</Link>
              </li>
              <li>
                <Link to="/ecommerce">Ecommerce development</Link>
              </li>
              <li>
                <Link to="/ecommerce-support">Ecommerce Support</Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to="/projects">Projects</Link>
          </li>
          <li>
            <Link to="/company">Company</Link>
          </li>

          <li>
            <Link to="/contact">Get a quote</Link>
          </li>
        </ul>
      </div>
    );
  }
}

const Home = () => (
  <div>
    <Slider />
    <Offer />
    <Intro />

    <Portfolio />
    <Techno />

    <ContactData />
    <ContactForm />
  </div>
);

const Ecomm = () => (
  <>
    <div id="slider">
      <div className="slider-image">
        <img src={amazon} />
      </div>
      <div className="slider-text" style={{ justifyContent: "space-around" }}>
        <h1 className="wow fadeInRight2">Ecommerce support</h1>

        <div>
          <p className="wow fadeInLeft2 ">
            Trust the development and filling us and get the first ones order
            from the first week of work!
          </p>
          <a href="#" className="button wow fadeInRight2 ">
            View portfolio
          </a>{" "}
        </div>
        {/*  wow fadeIn */}
      </div>
    </div>

    <div className="container">
      <h2 className="wow fadeIn2">
        Amazon becomes more and more competitive every day.
      </h2>

      <div className="block">
        <div className="block-left wow fadeInLeft2  " style={{ width: "80%" }}>
          <p>
            Gain the competitive edge and help your products be more visible and
            sell more!{" "}
          </p>{" "}
          <p>
            Proven techniques and services help you to rank higher! Launch a new
            product?{" "}
          </p>{" "}
          <p>
            Want to relaunch an old product? Want to jump from Nr.2# to Nr.1# ?{" "}
          </p>{" "}
          <p>
            Choose from our various proven techniques to rank higher and contact
            us today!{" "}
          </p>
        </div>

        <div className="block-right no-mobile" style={{ width: "20%" }}>
          <img src={rect} />
        </div>
      </div>

      <div className="block">
        <div className="block-left">
          <img src={img2} />
        </div>

        <div
          className="block-right wow fadeInRight2 "
          style={{ alignItems: "flex-start" }}
        >
          <p>
            SEO Listing creation including competitor research and analysis{" "}
          </p>
          <p>3d render product picture creation </p>
          <p>
            Video creation (for Product Detail page and Sponsored Brand
            Advertisement){" "}
          </p>
          <p>Brand store designing and building. </p>{" "}
          <p>
            Advertisement strategy creation and implementation according to your
            targets aims and goals.{" "}
          </p>
        </div>
      </div>

      <div className="block">
        <div className="block-left wow fadeInLeft2 ">
          <p>Prominent placement in Wishlist widget on Amazon </p>{" "}
          <p> Placement in frequently bought together widget </p>{" "}
          <p>Rank to individual keywords or improve existing ranking </p>
          <p>Search find add to basket via defined focus keyword </p>{" "}
          <p> Search find buy for focus keyword </p>{" "}
          <p>Remove top ranted bad feedbacks from the product display page</p>
        </div>

        <div className="block-right no-mobile">
          <img src={rect2} />
        </div>
      </div>
    </div>

    <div
      className="container wow fadeInUp2 "
      style={{
        marginTop: "140px",
        marginBottom: "160px",
        position: "relative",
      }}
    >
      <div className="no-mobile" style={{ position: "absolute", top: "-80px" }}>
        <img src={rect} />
      </div>
      <h2>Contact us now for individual consulting</h2>
      <div style={{ maxWidth: "600px", margin: "auto" }}>
        <p>
          Always ready to help. We have the answers to your questions! Let's
          take it for maintaining any CMS system and helping you grow your
          business.
        </p>
      </div>
      <a href="#" className="button">
        Our Contact
      </a>
      <div
        className="no-mobile"
        style={{ position: "absolute", bottom: "-80px", right: 0 }}
      >
        <img src={rect} />
      </div>
    </div>
  </>
);

const EcommerceSupport = () => (
  <div className="ecommerce">
    <Ecomm />
    <Techno />

    <ContactData />
    <ContactForm />
  </div>
);

///  data-animation-delay="0.3s"
const Slider = () => {
  return (
    <div id="slider">
      <div className="slider-image">
        <img src={imgslider} />
      </div>
      <div className="slider-text">
        <h2 className="wow fadeInRight2">
          Website Development And Administration
        </h2>
        <h3 className="wow fadeInLeft2">
          Web development, UI/UX, Support, CRM, Advertisement
        </h3>
        <p className="fadeIn2">
          Trust the development and filling us and get the first ones order from
          the first week of work!
        </p>
        <a href="#" className="button wow fadeInRight2">
          View portfolio
        </a>{" "}
        {/*  wow fadeIn */}
      </div>
    </div>
  );
};

const Offer = () => (
  <div id="offer" className="offer container">
    <div className="offer__left">
      <div className="offer-wrap">
        <div className="offer-inner wow fadeInLeft" data-wow-delay=".5s">
          <h3>What we offer</h3>
          <a href="#" className="button">
            View all services
          </a>
        </div>
      </div>
      <div className="offer-wrap">
        <div className="offer-inner wow fadeInLeft">
          <img src={icon1} />
          <h5>Website creation</h5>
          <p>
            Website development of any complexity: online stores, corporate
            sites, landings, blogs.
          </p>
        </div>
      </div>
      <div className="offer-wrap">
        <div className="offer-inner wow fadeInLeft" data-wow-delay=".3s">
          <img src={icon2} />
          <h5>Basic site setup</h5>
          <p>
            Setting up an online store for you is an important step in getting
            started.
          </p>
        </div>
      </div>
      <div className="offer-wrap">
        <div className="offer-inner wow fadeInLeft" data-wow-delay=".2s">
          <img src={icon3} />
          <h5>Web design solution</h5>
          <p>
            Yan Mall Solutions specialists will always find the right solution
            and help you implement the design idea.
          </p>
        </div>
      </div>
    </div>
    <div className="offer__right wow fadeInRight">
      <img src={offermobile} />
    </div>
  </div>
);

const Intro = () => (
  <div className="intro">
    <img className="intro__left" src={side} />
    <div className="intro__inner">
      <h2 className="wow fadeInLeft">Introduction Of Online Store</h2>
      <p className="wow fadeInRight">
        Always ready to help. We have the answers to your questions! Let's take
        it for maintaining any CMS system and helping you grow your business.
      </p>
      <div className="intro__buttons wow fadeInUp">
        <a href="#" className="button">
          Our contacts
        </a>
        <a href="#" className="button button-clear">
          Learn More
        </a>
      </div>
    </div>
    <img className="intro__right" src={side} />
  </div>
);

const ContactData = () => (
  <div id="contact-data" className="contacts container">
    <div className="contacts__item wow fadeInUp" data-wow-delay=".5s">
      <h4>Our phones</h4>
      <p>+7 (958) 756 85 34</p>
      <p>+38 (044) 38 38 238</p>
      <p>+38 (094) 928 52 38</p>
    </div>
    {/*
    <div className="contacts__item wow fadeInUp">
      <h4>Our address</h4>
      <p>
        79000 Lviv, Ukraine, <br />
        street Vladimir the Great, 4
      </p>
    </div>*/}
    <div className="contacts__item wow fadeInUp" data-wow-delay=".3s">
      <h4>Our email</h4>
      <p>e-mail: sales@yanmall.pro</p>
    </div>
  </div>
);

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = { name: "", email: "", phone: "", message: "" };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleSubmit(event) {
    alert("Your message is sent, " + this.state.value);
    event.preventDefault({ name: "", email: "", phone: "", message: "" });
    this.setState();
  }

  handleChange(event) {
    const name = this.setState({ value: event.target.value });
  }

  render() {
    return (
      <div id="contact-form" className="wow fadeInUp">
        <img className="contact-left no-mobile" src={leftcontact} />
        <img className="contact-right no-mobile" src={rightcontact} />
        <form onSubmit={this.handleSubmit}>
          <div className="form-inner">
            <h3>Get in Touch</h3>
            <p>
              Record your application and our manager will advise you on your
              services and cooperation
            </p>
            <input
              type="text"
              name="name"
              value={this.state.name}
              placeholder="Name"
              onChange={this.handleChange}
            />
            <input
              type="text"
              name="email"
              value={this.state.email}
              placeholder="Email"
              onChange={this.handleChange}
            />
            <input
              type="text"
              name="phone"
              value={this.state.phone}
              placeholder="Phone"
              onChange={this.handleChange}
            />
            <input
              type="text"
              name="message"
              value={this.state.message}
              placeholder="Message"
              onChange={this.handleChange}
            />
            <input type="submit" value="Send" />
          </div>
        </form>
      </div>
    );
  }
}

const Ecommerce = () => (
  <div>
    <h1>Ecommerce</h1>
  </div>
);

const Services = () => (
  <div>
    <h1>services</h1>
  </div>
);

const Webdev = () => (
  <div>
    <h1>webdev</h1>
  </div>
);

const Mobdev = () => (
  <div>
    <h1>mobdev</h1>
  </div>
);

const Uiux = () => (
  <div>
    <h1>Ui ux</h1>
  </div>
);

const Projects = () => (
  <div>
    <h1>projects</h1>
  </div>
);

const Company = () => (
  <div>
    <h1>company</h1>
  </div>
);

const Contact = () => (
  <div
    className="contact-page"
    style={{ backgroundImage: `url(${contactBackground})` }}
  >
    <ContactForm />
  </div>
);

const Footer = () => (
  <footer>
    <div className="footer-1">
      <h5>Contacts</h5>
      <p>sales@yanmall.pro</p>
    </div>
    <div className="footer-2">
      <div
        className="clutch-widget"
        data-url="https://widget.clutch.co"
        data-widget-type="2"
        data-height="50"
        data-darkbg="1"
        data-clutchcompany-id="722444"
      ></div>
    </div>
    <div className="footer-3">
      <img className="logo" src={toplogo} />
    </div>

    <div className="footer-4">
      {" "}
      {/*
      <div className="social-menu">
        <img src={facebook} />
        <img src={instagram} />
        <img src={linkedin} />
      </div>*/}
      <p>
        2022 © Yan Mall Solutions. <br /> All rights reserved
      </p>
    </div>
  </footer>
);

export default App;
