import React from 'react';
import { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Switch, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';




class Admin extends Component {
	constructor(props){
		super(props)
	}


	render() {
		return (
			<div>1</div>
		);
	}
}



export default Admin;
