import React from "react";
import { Component } from "react";

import Tabs from "../Tabs/Tabs.js";

import screen1 from "../../img/portfolio/screen1.png";
import screen2 from "../../img/portfolio/screen2.png";
import screen3 from "../../img/portfolio/screen3.png";
import screen4 from "../../img/portfolio/screen4.png";
import screen5 from "../../img/portfolio/screen5.png";
import screen6 from "../../img/portfolio/screen6.png";

import projectSm from "../../img/portfolio/project-sm.png";
import projectService from "../../img/portfolio/project-service.png";

class PortfolioDesc extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	onClose = () => {
		this.props.close();
		document.querySelector("body").style.overflow = "auto";
	};

	render() {
		//const show = this.state.show;

		//console.log(this.props.show)

		///this.props.show ---------------------------- bind????

		document.querySelector("body").style.overflow = "hidden";

		return (
			<>
				<div className="project__desc desc">
					<div className="desc__inner">
						<h2>Solo Step Solutions</h2>
						<p>Service</p>
						<p>Used technoliogies</p>
						<a href="#">solostepsolution.com</a>
						<img src={projectService} alt="service" />
					</div>
					<div className="overlay" onClick={this.onClose}></div>
				</div>
			</>
		);
	}
}

class PortfolioItem extends Component {
	constructor(props) {
		super(props);

		this.state = {
			show: false,
		};
	}

	onShow = () => {
		//this.setState({show:true});
		//	console.log(this.state.show);

		this.state.show
			? this.setState({ show: true })
			: this.setState({ show: true });
	};

	onClose = () => {
		this.setState({ show: false });
		console.log(this.state.show);
		//console.log("1");
	};

	render() {
		return (
			<div className="portfolio-item  ">
				<img src={projectService} onClick={this.onShow} />{" "}
				{/* Проблема при натисканні на div*/}
				{this.state.show ? <PortfolioDesc close={this.onClose} /> : null}
			</div>
		);
	}
}

///{this.state.showForm ? (<BookingPopupForm closeForm={this.closeForm} />) : null}

const Portfolio = () => (
	<div id="portfolio">
		<h2 className="fadeInRight2">Portfolio</h2>

		<div className="portfolio-inner container">
			<Tabs>
				<div label="All">
					{/*
			<div className="portfolio-item  ">
					<img src={projectService} />

					<div className="project__desc desc">
						<div className="desc__inner">
							<h2>Title</h2>
							<p>desc</p>
							<img src={projectService} alt="service" />
							<a href="#">Link</a>
						</div>
						<div className="overlay"></div>
					</div>
			</div>*/}
					<PortfolioItem />
					{/*
			<div className="portfolio-item  ">
					<img src={screen3} />
			</div>
			<div className="portfolio-item  ">
					<img src={screen4} />
			</div>
			<div className="portfolio-item  ">
					<img src={screen5} />
			</div>
			<div className="portfolio-item  ">
					<img src={screen6} />
			</div>
		*/}
				</div>
				<div label="Custom software">
					<div className="portfolio-item  ">
						<img src={screen1} />
					</div>
					<div className="portfolio-item  ">
						<img src={screen4} />
					</div>
					<div className="portfolio-item  ">
						<img src={screen5} />
					</div>
				</div>
				<div label="Web site administration">
					<div className="portfolio-item  ">
						<img src={screen2} />
					</div>
					<div className="portfolio-item  ">
						<img src={screen6} />
					</div>
				</div>
				<div label="Design">
					<div className="portfolio-item  ">
						<img src={screen3} />
					</div>
					<div className="portfolio-item  ">
						<img src={screen5} />
					</div>
				</div>
			</Tabs>
		</div>

		{/*
		<div className="portfolio-nav container filters-button-group">
			<ul>
				<li data="all" className="active" data-filter="*">All</li>
				<li data="custom" data-filter=".metal">Custom software</li>
				<li data="web" data-filter=".transition">Web site administration</li>
				<li data="design" data-filter=".alkali">Design</li>
			</ul>
		</div>
		<div className="grid portfolio-inner container">
			<div data="custom" className="element-item  portfolio-item transition metal">
				<img src={screen1} />
			</div>
			<div data="design" className="element-item  portfolio-item transition metal">
				<img src={screen1} />
			</div>
			<div data="web" className="element-item  portfolio-item">
				<img src={screen1} />
			</div>
			<div data="web" className="element-item  portfolio-item">
				<img src={screen1} />
			</div>
			<div data="custom" className="element-item portfolio-item">
				<img src={screen1} />
			</div>
			<div data="design" className="element-item portfolio-item">
				<img src={screen1} />
			</div>
			
		</div>
		
*/}
	</div>
);

export default Portfolio;
